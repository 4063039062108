import { defineStore } from "pinia";

import { Filter } from 'src/models/filter';
import { getBlankFilter } from '@/models/getBlankFilter';
import { paginationObject } from 'src/models/pagination';
import { initialPagination } from '@/models/initialPagination';

export const useStore = defineStore({
  id: "store",
  state: () => ({
    showFilter: true,
      refreshFilter: {
        token_id: "" as string | undefined,
        search_term: "" as string | undefined,
        search_categories: [] as string[] | undefined,
        search_traits: [] as string[] | undefined,
        time_frame: "" as string | undefined,
        updated_date: "" as string | undefined,
        start_date: "" as string | undefined,
        end_date: "" as string | undefined,
        state: "" as string | undefined,
        dates: [] as string[] | undefined,
      },
    filter: getBlankFilter() as Filter,
    pagination: initialPagination() as paginationObject,
  }),
  getters: {
    getShowFilter(state) {
      return state.showFilter;
    },
    getFilter(state) {
      return state.filter as Filter;
    },
    getPagination(state) {
      return state.pagination as paginationObject;
    },
    getPage(state) {
      return state.pagination.page;
    },
    getPageSize(state) {
      return state.pagination.pageSize;
    },
    getSortSelect(state) {
      return state.pagination.sortSelect;
    },
    getSortBy(state) {
      return state.pagination.sortBy;
    },
    getSortDirection(state) {
      return state.pagination.sortDirection;
    },
    getLimit(state) {
      return state.pagination.limit;
    },
    getContinuation(state) {
      return state.pagination.continuation;
    },
  },
  actions: {
    setShowFilter(value: boolean) {
      this.showFilter = value;
    },
    setFilterValue<K extends keyof Filter>(key: K, value: Filter[K]) {
      this.filter[key] = value;
    },
    setTokenId(value: string) {
      this.filter.token_id = value;
    },
    setSearchTerm(value: string) {
      this.filter.search_term = value;
    },
    setSearchDate(value: string) {
      this.filter.time_frame = value;
    },
    setSearchTypes(value: string) {
      if (!this.filter.search_types) {
        this.filter.search_types = [];
      }
      const result = this.filter.search_types.includes(value);
      if (!result) {
        /* Value not found in our Array, so add it */
        this.filter.search_types.push(value);
      } else {
        /* Find and Remove item from the Array by Index */
        const index = this.filter.search_types.indexOf(value);
        this.filter.search_types.splice(index, 1);
      }
    },
    setSearchCategories(value: string) {
      if (!this.filter.search_categories) {
        this.filter.search_categories = [];
      }
      const result = this.filter.search_categories.includes(value);
      if (!result) {
        /* Value not found in our Array, so add it */
        this.filter.search_categories.push(value);
      } else {
        /* Find and Remove item from the Array by Index */
        const index = this.filter.search_categories.indexOf(value);
        this.filter.search_categories.splice(index, 1);
      }
    },
    setSearchTraits(value: string) {
      if (!this.filter.search_traits) {
        this.filter.search_traits = [];
      }
      const result = this.filter.search_traits.includes(value);
      if (!result) {
        /* Value not found in our Array, so add it */
        this.filter.search_traits.push(value);
      } else {
        /* Find and Remove item from the Array by Index */
        const index = this.filter.search_traits.indexOf(value);
        this.filter.search_traits.splice(index, 1);
      }
    },
    setPriceMinInput(value: string) {
      this.filter.price_min_input = value;
    },
    setPriceMaxInput(value: string) {
      this.filter.price_max_input = value;
    },
    setRarityMinInput(value: string) {
      this.filter.rarity_min_input = value;
    },
    setRarityMaxInput(value: string) {
      this.filter.rarity_max_input = value;
    },
    resetFilter() {
      this.filter = <Filter>getBlankFilter();
      this.refreshFilter.token_id = "";
      this.refreshFilter.search_term = "";
      this.refreshFilter.search_categories = [];
      this.refreshFilter.search_traits = [];
      this.refreshFilter.time_frame = "";
      this.refreshFilter.updated_date = "";
      this.refreshFilter.start_date = "";
      this.refreshFilter.end_date = "";
      this.refreshFilter.state = "";
      this.refreshFilter.dates = ["", ""];
    },
    setPage(value: number) {
      this.pagination.page = value;
    },
    setPageSize(value: number) {
      this.pagination.pageSize = value;
    },
    setSortSelect(value: string) {
      this.pagination.sortSelect = value;
    },
    setSortBy(value: string) {
      this.pagination.sortBy = value;
    },
    setSortDirection(value: string) {
      this.pagination.sortDirection = value;
    },
    setLimit(value: number) {
      this.pagination.limit = value;
    },
    setContinuation(value: string) {
      this.pagination.continuation = value;
    },
    resetPagination() {
      this.pagination = <paginationObject>initialPagination();
    },
  }
});
